:root {
  --main-accent: #0084ff;
  --text: #1f2933;
  --background: #f5f7fa;
  --white: white;
  --accent3: #a9cecc;
  --text-alpha-25: rgba(31, 41, 51, .25);
  --text-alpha-75: rgba(31, 41, 51, .75);
  --secondary-accent: #e1f0fd;
  --accent4: #dfcac2;
  --button-border: #e8ecef;
}

/* Custom styles */

.next-button:disabled {
  opacity: 0.5;
}

.signup-links {
  color: var(--main-accent);
  border-bottom: 1px #000;
  text-decoration: none;
}

.ae-response-wrap a {
  color: var(--main-accent);
  border-bottom: 1px #000;
  text-decoration: none;
}

.save-button {
  border: 2px solid var(--button-border);
  border-radius: 5px;
  padding: 12px 32px;
  font-size: 15px;
}

/* End custom styles */

a {
  color: var(--main-accent);
  border-bottom: 1px #000;
  text-decoration: none;
}

a:hover {
  border-bottom-style: none;
  border-bottom-color: var(--main-accent);
}

.nav-div {
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
  display: block;
}

.logo-div {
  width: 20%;
}

.links-div {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.links-list {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-link {
  color: var(--text);
  font-weight: 400;
  text-decoration: none;
}

.nav-link:hover {
  border: 1px #000;
}

.nav-link.nav-login {
  background-color: var(--text);
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.body {
  background-color: var(--background);
  color: var(--text);
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
}

.nav-item {
  margin-left: 25px;
}

.button {
  background-color: var(--text);
  color: #fff;
  border-radius: 20px;
  padding: 6px 32px;
  font-weight: 400;
}

.button.w--current {
  padding: 10px 24px;
}

.nav-container {
  max-width: 1280px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  display: flex;
}

.hero {
  min-height: 85vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1280px;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.cta-button {
  color: var(--white);
  background-color: #4763ff;
  border-radius: 50px;
  padding: 12px 42px;
  font-size: 16px;
  font-weight: 400;
}

.hero-h1 {
  font-size: 60px;
  font-weight: 500;
  line-height: 1.3em;
}

.div-block {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.hero-p {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 1.6em;
}

.div-block-2 {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-img-holder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.content-holder-dark {
  background-color: var(--text);
  border-radius: 30px;
  padding: 100px 20px;
  box-shadow: -3px 3px 20px 4px rgba(0, 0, 0, .15);
}

.home-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.h2-home {
  color: var(--background);
  font-size: 57px;
  font-weight: 400;
  line-height: 1.1em;
}

.h2-home.dark-text {
  width: 75%;
  color: var(--text);
  text-align: center;
  margin-bottom: 15px;
}

.info-card-holder {
  flex-direction: column;
  justify-content: center;
  padding: 24px 50px;
  display: flex;
}

.card-heading-holder {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
}

.card {
  background-color: var(--accent3);
  border-radius: 20px;
  margin-bottom: 25px;
  padding: 25px;
}

.heading-2 {
  margin-top: 0;
  font-weight: 500;
}

.image {
  z-index: 2;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.paragraph {
  color: var(--background);
  margin-bottom: 40px;
  font-size: 20px;
}

.image-2 {
  margin-bottom: 25px;
}

.content-holder {
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.content-holder.dark-rounded {
  min-height: 50vh;
  background-color: var(--text);
  border-radius: 30px;
  justify-content: center;
}

.content-holder.dark-rounded.center {
  align-items: center;
}

.heading-3 {
  text-align: center;
  font-weight: 500;
}

.leading-text {
  color: var(--main-accent);
  letter-spacing: .5em;
  font-weight: 500;
}

.leading-text.alt {
  color: var(--accent3);
}

.paragraph-2 {
  width: 60%;
  text-align: center;
}

.paragraph-2.bottom-spacing {
  margin-bottom: 50px;
}

.paragraph-2.light {
  color: var(--background);
}

.simplify-grid {
  width: 100%;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 50px;
  display: grid;
}

.simplify-grid-card {
  background-color: var(--white);
  border-radius: 30px;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  display: flex;
  box-shadow: 0 1px 20px -1px rgba(0, 0, 0, .1);
}

.heading-4 {
  width: 80%;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.paragraph-3 {
  width: 80%;
  text-align: center;
  font-size: 16px;
}

.section-2 {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.lines-image {
  float: none;
  position: absolute;
  top: 25%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.faqs-section {
  min-height: 100vh;
}

.accordian-item {
  width: 100%;
  border: 2px solid #e8ecef;
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.accordian-toggle {
  height: 80px;
  border-bottom: 2px #e8ecef;
  align-items: center;
  display: flex;
}

.text-block-55 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
}

.dropdown-list {
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  display: block;
  position: static;
}

.text-block-56 {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
}

.link:hover {
  border-bottom-style: solid;
}

.footer {
  padding-top: 50px;
}

.footer-content {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-link-div {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: grid;
}

.image-3 {
  margin-bottom: 15px;
}

.fooer-divider {
  width: 100%;
  height: 1px;
  background-color: var(--text-alpha-25);
  margin-top: 60px;
  margin-bottom: 40px;
}

.div-block-3 {
  width: 100%;
}

.footnote-text {
  font-size: 14px;
}

.foot-links-holder {
  flex-direction: column;
  display: flex;
}

.footer-link {
  color: var(--text-alpha-75);
  margin-bottom: 5px;
  font-weight: 300;
}

.footer-link:hover {
  border-bottom-style: none;
}

.text-block-57 {
  margin-bottom: 15px;
  font-weight: 500;
}

.cta-card-button {
  background-color: var(--main-accent);
  border-radius: 50px;
  margin-top: 36px;
  padding: 12px 42px;
  font-weight: 300;
}

.section-3 {
  height: 60vh;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.heading-5 {
  font-weight: 500;
}

.body-2 {
  background-color: var(--background);
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
}

.form-holder {
  width: 600px;
  max-width: 600px;
  background-color: var(--white);
  border-radius: 30px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  display: flex;
  box-shadow: 0 8px 20px 6px rgba(0, 0, 0, .1);
}

.auth-heading {
  font-weight: 500;
}

.ae-form-label {
  margin-bottom: 12px;
  font-weight: 500;
}

.form {
  margin-top: 30px;
}

.ae-form-field {
  height: 50px;
  background-color: var(--background);
  border-radius: 10px;
  margin-bottom: 24px;
}

.ae-form-field:focus {
  border: 1px solid var(--main-accent);
}

.text-field-2 {
  height: 50px;
}

.submit-button {
  background-color: var(--main-accent);
  border-radius: 50px;
  padding: 12px 42px;
}

.form-column-left {
  padding-left: 0;
  padding-right: 10px;
}

.form-column-right {
  padding-right: 0;
}

.auth-text {
  margin-bottom: 24px;
}

.text-block-59 {
  margin-bottom: 12px;
}

.div-block-4 {
  margin-bottom: 24px;
}

.login-links {
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.login-link {
  color: var(--main);
}

.body-3 {
  background-color: var(--background);
  color: var(--text);
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5em;
}

.dash-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.dash-nav {
  width: 20vw;
  background-color: #f6f8fe;
  flex-direction: column;
  justify-content: space-between;
  padding: 19px 19px 19px 18px;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.dash-main {
  width: 80vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 20px;
  display: flex;
  position: relative;
  left: 20vw;
  overflow: scroll;
  background-color: #fff;
}

.nav-logo {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.dash-nav-link {
  width: 100%;
  color: var(--text);
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  transition: background-color .2s;
  display: flex;
}

.dash-nav-link:hover {
  color: var(--main-accent);
  background-color: #e1f0fd;
  border-radius: 10px;
  font-weight: 300;
}

.dash-nav-link-active {
  color: var(--main-accent);
  background-color: #e1f0fd;
  border-radius: 10px;
  font-weight: 300;
}

.link-block {
  width: 100%;
}

.text-block-60 {
  padding: 16px 30px;
}

.dash-nav-list-item {
  margin-bottom: 5px;
}

.html-embed {
  margin-right: 10px;
}

.chatbot-container {
  width: 600px;
}

.dash-main-header {
  width: 100%;
}

.chat-holder-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.chat-holder {
  width: 700px;
}

.form-fail {
  color: var(--text);
  background-color: #fee8ed;
  border-radius: 10px;
  margin-top: 15px;
  padding: 10px;
}

.error-text {
  color: var(--text-alpha-75);
  margin-bottom: 0;
  font-size: 16px;
}

.dash-main-heading {
  font-weight: 500;
}

.form-full {
  justify-content: center;
  align-items: flex-start;
  padding-top: 90px;
  padding-left: 3%;
  padding-right: 3%;
  display: none;
}

.form-wrap {
  width: 100%;
  max-width: 390px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.slider-content-wrap {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 90px 0 75px;
  display: flex;
  position: relative;
}

.form07_link {
  color: #5b3acc;
  font-size: 13px;
  font-weight: 700;
}

.text-counter {
  color: rgba(91, 97, 124, .45);
  font-size: 14px;
}

.checkbox-image {
  width: 45px;
}

.form-content {
  width: 100%;
  margin-bottom: -20px;
}

.form-content.final {
  margin-bottom: 0;
}

.text-block {
  color: var(--main-accent);
  font-weight: 500;
}

.counter-image {
  width: 16px;
  margin-right: 5px;
}

.form12_checkbox_field {
  width: 140px;
  height: 120px;
  background-color: #fff;
  border: 1.2px solid rgba(62, 52, 200, .1);
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 35px 22px 22px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.form-wrapper {
  margin-bottom: 0;
  padding: 0;
  display: block;
}

.field-input {
  width: 100%;
  min-height: 45px;
  color: rgba(0, 0, 55, .75);
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid rgba(62, 52, 200, .04);
  border-radius: 6px;
  font-size: 16px;
}

.field-input:focus {
  border-color: #0064fe;
}

.field-input::-ms-input-placeholder {
  color: rgba(0, 0, 55, .1);
  font-family: Varela Round, sans-serif;
}

.field-input::placeholder {
  color: rgba(0, 0, 55, .1);
  font-family: Varela Round, sans-serif;
}

.error-message {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-section-title {
  color: var(--text);
  margin-bottom: 12px;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
}

.line-bottom {
  width: 100%;
  height: 1px;
  background-color: #c5c7ca;
}

.checkbox-field {
  width: 24%;
  height: 140px;
  min-width: 150px;
  border: 1px #000;
  justify-content: flex-start;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  display: flex;
}

.next {
  width: auto;
  height: 47px;
  max-width: 159px;
  justify-content: center;
  align-items: center;
  margin: auto auto 25px;
  display: block;
  position: absolute;
  right: 35px;
  overflow: visible;
}

.arrow {
  width: 16px;
  margin-right: 8px;
}

.checkbox-title {
  color: #5b3acc;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.previous-button {
  opacity: 1;
  color: #5b617c;
  text-indent: -2px;
  flex: none;
  align-self: center;
  padding: 5px 0;
  font-size: 15px;
  transition: opacity .2s ease-in;
}

.previous-button:hover {
  opacity: 1;
  border-bottom-color: rgba(0, 0, 0, .55);
}

.mobile-nav-bottom {
  display: none;
}

.previous {
  width: auto;
  height: 35px;
  max-width: 100px;
  opacity: .45;
  justify-content: center;
  align-items: center;
  margin: auto auto 30px 30px;
  transition: opacity .2s;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.previous:hover {
  opacity: 1;
}

.next-button {
  background-color: var(--main-accent);
  color: var(--background);
  border-radius: 5px;
  flex: 1;
  align-self: center;
  padding: 12px 32px;
  font-size: 15px;
  margin-left: 5px;
  transition: background-color .2s ease-in;
}

.title-left {
  margin-top: 15px;
  margin-left: 15px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.submit-button-2 {
  width: 260px;
  height: auto;
  background-color: var(--main-accent);
  box-shadow: none;
  letter-spacing: 1px;
  border-radius: 5px;
  margin-bottom: -60px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  transition: background-color .2s ease-in, box-shadow .2s ease-in;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider {
  width: 75vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
}

.form-title-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  display: flex;
}

.field-title {
  color: #000037;
  background-color: #fff;
  margin-bottom: 5px;
  padding-left: 6px;
  font-size: 14px;
  font-weight: 400;
}

.ae-form-paragraph {
  max-width: 550px;
  color: #5b617c;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  line-height: 23px;
}

.slide {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  flex-wrap: wrap;
  padding-top: 0;
  padding-bottom: 0;
  position: static;
}

.details-right {
  align-items: center;
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.checkbox {
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.checkbox:active {
  border: 1px solid #000;
}

.success-message {
  letter-spacing: 1px;
  padding: 50px;
}

.form07_legal {
  color: rgba(16, 28, 46, .54);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.checkbox_wrap {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox_wrap.checkbox_centre {
  justify-content: center;
}

.ae-form-holder {
  padding-top: 90px;
  padding-left: 3%;
  padding-right: 3%;
}

.ae-form-content-wrap {
  flex-direction: column;
  align-items: center;
  margin-bottom: 75px;
  display: flex;
}

.ae-form-top-holder {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
  display: flex;
}

.ae-form-details-right {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.ae-form-heading {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  display: flex;
}

.ae-form-field-holder {
  width: 100%;
  margin-bottom: 100px;
}

.ae-form-wrap {
  max-width: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.ae-form-button-holder {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.ae-form-prev-btn {
  align-items: center;
  display: flex;
}

.checkbox-field-2 {
  width: 24%;
  height: 140px;
  min-width: 150px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  padding: 25px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.checkbox-2 {
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: 2px rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.checkbox-2:active {
  border: 1px solid #000;
}

.ae-form-radio-holder {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ae-radio-field {
  width: 24%;
  height: 140px;
  min-width: 150px;
  border: 1.2px solid rgba(62, 52, 200, .1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  padding: 25px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.ae-radio-image {
  z-index: 12;
  width: 45px;
  position: relative;
}

.radio {
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  border: 2px rgba(0, 0, 0, 0);
  border-radius: 0;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.radio:active {
  border: 1px solid #000;
}

.radio.w--redirected-checked {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-text-fill-color: inherit;
  background-color: #f1fff9;
  background-clip: border-box;
  border-style: none;
  border-color: #68757f;
  border-radius: 0%;
  display: block;
}

.ae-radio-title {
  z-index: 12;
  color: #5b3acc;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.checkbox_wrap-2 {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox-field-3 {
  width: 24%;
  height: 140px;
  min-width: 150px;
  border: 1.2px solid rgba(62, 52, 200, .1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  padding: 25px;
  display: flex;
  position: relative;
  box-shadow: 1px 1px 35px rgba(198, 211, 255, .28);
}

.checkbox-image-2 {
  z-index: 12;
  width: 45px;
  position: relative;
}

.checkbox-title-2 {
  z-index: 12;
  color: #5b3acc;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.ae-form-response-holder {
  width: 100%;
  margin-bottom: 100px;
}

.ae-response-wrap {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.response-p {
  margin-bottom: 0;
}

.mobile-nav-logo {
  display: none;
}

.list {
  padding-left: 0;
}

.mobile-menu-btn, .close-menu-btn, .mobile-logo-div {
  display: none;
}

@media screen and (max-width: 991px) {
  .logo-div {
    width: 30%;
  }

  .form-holder {
    width: auto;
  }

  .dash-nav {
    width: 15vw;
    align-items: stretch;
    padding: 15px;
  }

  .dash-main {
    width: 85vw;
    left: 15vw;
  }

  .dash-upper-nav {
    flex-direction: column;
    justify-content: flex-start;
    align-self: auto;
    align-items: center;
    display: flex;
  }

  .nav-logo {
    display: none;
  }

  .dash-nav-link {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .dash-nav-list-item {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .dash-nav-footer {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .html-embed {
    margin-right: 0;
  }

  .dash-main-heading {
    font-size: 30px;
  }

  .text-block {
    -webkit-text-stroke-color: var(--main-accent);
  }

  .form-section-title {
    font-size: 26px;
  }

  .nav-text, .text-block-62, .text-block-63, .text-block-64, .text-block-65 {
    display: none;
  }

  .mobile-nav-logo {
    width: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: block;
  }

  .text-block-66 {
    display: none;
  }

  .list {
    width: 100%;
    flex-direction: column;
    padding-left: 0;
    display: flex;
  }

  .mobile-menu-btn, .close-menu-btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {

.logo-div {
  width: 40%;
  display: none;
}

.content-holder {
  padding-left: 20px;
  padding-right: 20px;
}

.form-holder {
  padding: 31px;
}

.form-column-right {
  padding-left: 0;
}

.dash-nav {
  z-index: 0;
  margin-left: -15vw;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
  transition: margin 200ms ease-in;
}

/* Custom style  */

.dash-nav-show {
  z-index: 0;
  margin-left: 0vw;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
  transition: margin 200ms ease-out;
}

.dash-main {
  width: 100vw;
  position: static;
}

.dash-main-header {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  display: flex;
}

.dash-main-heading {
  font-size: 1.8em;
  line-height: 1.2em;
}

.form-content {
  width: 90%;
}

.form12_checkbox_field {
  width: 48%;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}

.form-wrapper {
  width: 100%;
}

.next {
  z-index: 6;
  position: fixed;
}

.mobile-nav-bottom {
  z-index: 5;
  height: 100px;
  background-color: #fff;
  border-top: 1px solid rgba(131, 149, 177, .15);
  display: block;
  position: fixed;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.previous {
  z-index: 6;
  position: fixed;
}

.checkbox_wrap, .checkbox_wrap-2 {
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-menu-btn {
  width: 30px;
  height: 20px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  align-self: auto;
  align-items: center;
  display: flex;
  position: fixed;
  top: 20px;
  bottom: auto;
  left: 20px;
  right: auto;
}

.menu-top-bar {
  width: 100%;
  height: 2px;
  background-color: var(--text);
  padding-bottom: 0;
  padding-right: 0;
  position: static;
}

.menu-middle-bar, .menu-bottom-bar {
  width: 100%;
  height: 2px;
  background-color: var(--text);
  padding-bottom: 0;
  padding-right: 0;
}

.close-menu-btn {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.columns {
  flex-direction: column;
  display: block;
}

.mobile-logo-div {
  display: block;
}
}

@media screen and (max-width: 479px) {
.form-holder {
  padding: 20px;
}

.dash-main-header {
  align-items: flex-start;
  display: flex;
}

.dash-main-heading {
  font-size: 1.6em;
}

.slider-content-wrap {
  width: 100%;
  justify-content: center;
  margin-bottom: 140px;
  padding: 50px 10px;
}

.form-content {
  width: 100%;
}

.text-block {
  font-size: 16px;
}

.form12_checkbox_field {
  width: 49%;
  height: 130px;
  min-width: auto;
  margin-bottom: 15px;
  margin-right: 0;
}

.form-section-title {
  text-align: center;
  font-size: 1.3em;
  line-height: 1.3em;
}

.mask {
  height: 100%;
  position: static;
}

.previous {
  max-width: 75px;
  display: flex;
}

.submit-button-2 {
  margin-bottom: -85px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slider {
  height: auto;
}

.ae-form-paragraph {
  font-size: .8em;
  line-height: 1.5em;
}

.checkbox_wrap, .checkbox_wrap-2 {
  flex-wrap: wrap;
}
}

#w-node-_079669bc-2139-6867-2f93-414db82b1b68-f6cbd977, #w-node-d4b73d70-6063-110a-43c3-cfd2a31802a1-f6cbd977, #w-node-_9f3c29e8-51f7-a951-fb47-2a8b7d131b41-f6cbd977, #w-node-c48116d8-4875-2f54-43e0-66a007aaa2de-f6cbd977, #w-node-ac91c968-f52d-a1f7-6479-39fd8c1696fd-f6cbd977, #w-node-f1b17d4a-346f-cd4e-9020-31a46352cc9d-f6cbd977, #w-node-b963011c-ccea-2843-b189-9cb34e1c319e-f6cbd977 {
grid-area: span 1 / span 1 / span 1 / span 1;
}

