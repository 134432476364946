.results-holder {
    width: 100%;
    max-width: 800px;
    margin-bottom: 100px;
}

.result-item {
    border: 1px none var(--text-alpha-25);
    border-radius: 10px;
    flex-direction: column;
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, .1);
}

.result-title {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
}

.result-heading {
    margin-top: 10px;
    margin-bottom: 5px;
}

.result-website {
    color: var(--background);
    background-color: #0084ff;
    border-radius: 5px;
    padding: 5px 20px;
    text-decoration: none;
    transition: background-color .2s;
}

.result-website:hover {
    background-color: var(--text);
}

.text-block-68 {
    color: var(--background);
    font-size: 15px;
}

.result-info-holder {
    background-color: var(--background);
    flex-direction: row;
    align-items: center;
    padding: 20px 20px 10px;
    display: block;
}

.result-info {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    display: flex;
}

.result-info-card {
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    align-items: flex-start;
    display: flex;
}

.result-info-data {
    width: auto;
    max-width: 160px;
    font-size: 14px;
    font-weight: 500;
}

.result-info-data.full {
    width: auto;
    max-width: 600px;
}

.result-info-label {
    color: var(--text-alpha-75);
    margin-right: 10px;
    font-size: 14px;
}

.result-info-income-holder {
    border-top: 1px solid rgba(31, 41, 51, .1);
    align-items: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}

.title-holder {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.match {
    background-color: rgba(82, 186, 105, .2);
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 13px;
    display: flex;
}

.match-text {
    color: #52ba69;
    font-size: 14px;
    font-weight: 400;
}

.potential {
    background-color: rgba(254, 171, 73, .2);
    border-radius: 5px;
    padding-left: 13px;
    padding-right: 13px;
}

.potential-text {
    color: #e68a1d;
    font-size: 14px;
    font-weight: 400;
}


@media screen and (max-width: 991px) {
    .result-info {
        grid-column-gap: 16px;
        grid-row-gap: 16px;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;
    }
}


@media screen and (max-width: 767px) {

}


@media screen and (max-width: 479px) {
    .result-title {
        flex-direction: column;
        align-items: flex-start;
    }

    .result-info {
    grid-template-columns: 1fr;
    }

    .title-holder {
        flex-direction: column;
        order: -1;
        align-items: flex-start;
        margin-bottom: 10px;
      }
    
    .result-heading {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .match {
        align-items: flex-start;
      }
}