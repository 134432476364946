
.chat-holder-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}

.chat-holder {
    width: 100%;
    height: 100%;
    margin: auto 0;
}