.settings-holder {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    display: flex;
}

.settings-info {
    width: 1000px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 35px 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
}

.settings-info-title {
    margin-bottom: 25px;
    font-weight: 500;
}

.field-holder {
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}

.input-holder {
    width: 700px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.settings-input {
    height: 50px;
    border: 1px none var(--text-alpha-25);
    background-color: var(--background);
    color: var(--text);
    border-radius: 10px;
    margin-bottom: 0;
    margin-left: 0;
    position: static;
}

.settings-input.margin-left-10 {
    margin-left: 10px;
  }

.button-holder {
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}

.settings-submit {
    background-color: var(--main-accent);
    border-radius: 5px;
}

@media screen and (max-width: 991px) {
    .settings-info {
        width: 100%;
    }

    .input-holder {
        width: 70%;
    }
}


@media screen and (max-width: 767px) {

}


@media screen and (max-width: 479px) {
    .field-holder {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-holder {
        width: 100%;
    }

    .input-holder.mobile-input {
        flex-direction: column;
        align-items: flex-start;
        display: flex;
    }
}