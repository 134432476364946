.history-table-holder {
    margin-top: 30px;
}

.history-table-top-holder {
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 25px;
    display: flex;
}

.table-rows-holder {
    -webkit-text-fill-color: inherit;
    background-clip: border-box;
    border: 1px solid rgba(31, 41, 51, .15);
    border-radius: 10px;
    overflow: hidden;
}

.history-table-row {
    color: var(--text);
    border: 1px solid rgba(31, 41, 51, .15);
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
    text-decoration: none;
    display: flex;
    overflow: hidden;
}

.history-table-row:hover {
    box-shadow: 0 2px 8px 0 var(--text-alpha-25);
}

.table-row-title-text {
    font-weight: 500;
}

.table-row-actions-holder {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.table-row-date-text {
    margin-left: 5px;
    font-size: 14px;
    line-height: 1.3em;
}

.table-row-delete-icon {
    margin-left: 25px;
}


.table-row-delete-icon:hover {
    color: #a02f44;
}