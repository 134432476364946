.form-fail {
    color: var(--text);
    background-color: #fee8ed;
    border-radius: 10px;
    margin-top: 15px;
    padding: 15px;
}

.error-text {
    color: var(--text-alpha-75);
    margin-bottom: 0;
    font-size: 16px;
}